.services-container {
  grid-column-start: 3;
  grid-column-end: 12;
  background-color: var(--white);
  border-radius: 10px;
  min-height: 100px;
}

.title {
  padding: 20px;
  border-bottom: 1px solid var(--ultra-light-grey);
  display: flex;
  position: relative;
}

.item {
  padding: 20px;
  border-bottom: 1px solid var(--ultra-light-grey);
  position: relative;
  display: flex;
}

.item:hover {
  background-color: var(--ultra-light-grey);
}

.add-services {
  align-self: center;
  position: absolute;
  right: 20px;
}

.review-service {
  display: none;
  position: absolute;
  right: 60px;
}

.delete-service {
  display: none;
  position: absolute;
  right: 20px;
}

.reminder-service {
  display: none;
  position: absolute;
  right: 100px;
}

.item > .circle-button.reminder-service {
  background-color: var(--light-grey);
  padding: 18px;
}

.item > .circle-button.delete-service {
  background-color: var(--error);
  padding: 18px;
}

.item > .circle-button {
  background-color: var(--success);
  padding: 8px;
  margin: 0;
  text-decoration: none;
  margin: -10px 10px 0 10px;
}

.item:hover > .circle-button {
  display: flex;
}
