.generator-container {
  background-color: var(--white);
  border-radius: 10px;
  min-height: 100px;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 10px;
}

.title {
  display: flex;
  justify-content: center;
}

.plan-title {
  font-size: 18.666666666666664px;
  color: #e6635a;
  background-color: transparent;
  font-weight: 700;
  font-style: normal;
  font-variant: normal;
  vertical-align: baseline;
  white-space: pre;
  text-decoration: underline;
}

.plan-ul {
  list-style-type: none;
}

.plan-ul li:before {
  content: '\2014';
  position: absolute;
  margin-left: -20px;
}

.table-container {
  display: flex;
  justify-content: center;
}

.tabla-sueno {
  margin-left: 0px;
  margin-right: 0px;
  width: 600px;
  border: 1px solid var(--black);
  border-collapse: collapse;
}

.tabla-column-title {
  height: 33px;
  display: table-cell;
  width: 231px;
  background-color: var(--orange);
  border-width: 1px;
  border-color: rgb(0, 0, 0);
  border-style: solid;
  text-align: center;
}

.tabla-column-value {
  height: 33px;
  display: table-cell;
  border-width: 1px;
  border-color: rgb(0, 0, 0);
  border-style: solid;
  text-align: center;
}

.tener {
  font-size: 14.666666666666666px;
  color: var(--black);
  background-color: transparent;
  font-weight: 700;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
  text-decoration: underline;
}

.redLetter {
  color: var(--error);
}
