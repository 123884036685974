.services-container {
  grid-column-start: 3;
  grid-column-end: 10;
  background-color: var(--white);
  border-radius: 10px;
  min-height: 100px;
}

.title {
  padding: 20px;
  border-bottom: 1px solid var(--ultra-light-grey);
  display: flex;
  position: relative;
}

.new-form {
  padding: 20px;
}

.initial_questions {
  display: grid;
}

.buttons-container {
  display: grid;
  grid-template-columns: repeat(6, 10px 1fr);
}

.cancel-button {
  grid-column-start: 10;
  grid-column-end: 11;
  justify-self: end;
}

.save-button {
  grid-column-start: 12;
  grid-column-end: 13;
  justify-self: end;
}
