.section {
  margin-top: 10px;
  background-color: var(--ultra-light-grey);
  padding: 10px;
  border-radius: 5px;
}

.section-title {
  font-size: 16px;
}

.options {
  margin-left: 20px;
}

.delete-section {
  float: right;
  background-color: var(--error);
}
