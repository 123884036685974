/* divider generado con https://www.shapedivider.app/*/

.service-container {
  grid-column-start: 4;
  grid-column-end: 12;
  border-radius: 10px;
  min-height: 800px;
  position: relative;
  background-color: var(--white);
  box-shadow: 4px 10px 18px -8px rgba(0, 0, 0, 0.75);
}

.service-background-image {
  width: 100%;
  display: block;
  height: 330px;
  position: absolute;
  border-radius: 10px;
}

.custom-shape-divider-bottom-1600931425 {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  margin-top: 262px;
}

.custom-shape-divider-bottom-1600931425 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 68px;
}

.custom-shape-divider-bottom-1600931425 .shape-fill {
  fill: #ffffff;
}

.service-content {
  padding: 40px;
}

.description-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 10px 1fr);
}

.service-description {
  grid-column-start: 1;
  grid-column-end: 5;
}

.service-detail {
  grid-column-start: 6;
  grid-column-end: 12;
}

.service-details-items {
  padding: 10px;
  border: 1px solid var(--ultra-light-grey);
  border-radius: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
}

h1 {
  width: 100%;
  font-family: 'Quicksand', sans-serif;
  font-weight: 300;
  display: grid;
  place-items: center;
}
