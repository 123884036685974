.dashboard-container {
  background-color: var(--white);
  border-radius: 10px;
  min-height: 100px;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 10px;
}

.dashboard-table-container {
  width: 100%;
  overflow-x: scroll;
}

@media only screen and (max-width: 1080px) {
  .dashboard-container {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0;
  }
}
