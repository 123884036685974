.users-container {
  grid-column-start: 3;
  grid-column-end: 12;
  background-color: var(--white);
  border-radius: 10px;
  min-height: 100px;
}

.search-form {
  display: flex;
  width: 200px;
}
