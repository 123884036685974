.main {
  margin-top: 30px;
  display: grid;
  place-items: center;
}

.content {
  margin: 0;
  max-width: 1200px;
  width: 100%;
  margin-top: 30px;
  padding: 3em;
}
