.users-container {
  grid-column-start: 1;
  grid-column-end: 13;
  background-color: var(--white);
  border-radius: 10px;
  min-height: 100px;
}

.search-form {
  display: flex;
  width: 200px;
}

.table-container {
  padding: 10px;
  overflow-x: scroll;
}

.payment-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.payment-table td,
.payment-table th {
  border: 1px solid #ddd;
  padding: 8px;
  white-space: nowrap;
}

.payment-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.payment-table tr:hover {
  background-color: #ddd;
}

.payment-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: var(--light-brown);
  color: var(--white);
}

.payment-succeeded {
  padding: 5px;
  background-color: var(--success);
  color: var(--white);
  border-radius: 5px;
}

.payment-incomplete {
  padding: 5px;
  background-color: var(--warning);
  color: var(--black);
  border-radius: 5px;
}

.excel-invoice {
  border: 1px solid var(--light-brown);
  width: 300px;
  margin: 20px;
  padding: 10px;
}

.forms-container {
  display: flex;
}

.sugestionsByName {
  width: 350px;
  height: 200px;
  background-color: var(--white);
  position: absolute;
}

.sugestionsBtn {
  background-color: var(--light-brown);
  width: 350px;
  border: 1px solid var(--black);
}

.sugestionsBtn:hover {
  background-color: var(--dark-brown);
}
