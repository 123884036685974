.documents-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.document-container {
  display: grid;
  font-size: 50px;
  place-items: center;
  cursor: pointer;
}

.document-title {
  font-size: 12px;
}

.close {
  color: var(--error);
  float: left;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
