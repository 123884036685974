.couponsContainer {
  grid-column-start: 3;
  grid-column-end: 10;
  background-color: var(--white);
  border-radius: 10px;
  min-height: 100px;
}

.title {
  padding: 20px;
  border-bottom: 1px solid var(--ultra-light-grey);
  display: flex;
  position: relative;
}

.newForm {
  padding: 20px;
}

.initial_questions {
  display: grid;
}

.buttons-container {
  display: grid;
  grid-template-columns: repeat(6, 10px 1fr);
}

.cancel-button {
  grid-column-start: 10;
  grid-column-end: 11;
  justify-self: end;
}

.save-button {
  grid-column-start: 12;
  grid-column-end: 13;
  justify-self: end;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.deleteSelectedServices {
  display: flex;
  place-items: center;
}

.deleteSelectedServicesBtn {
  background-color: var(--error);
  color: var(--black);
  padding: 16px;
  margin: 8px 10px 0 0;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  font-size: 20px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  display: flex;
  text-align: center;
  justify-content: center;
  touch-action: none;
  align-items: center;
}
