@import 'fonts.css';

:root {
  --black: #000000;
  --white: #ffffff;
  --grey: #4a4a4a;
  --ultra-light-grey: #f0f0f0;
  --light-grey: #dbdbdb;
  --light-brown: #f4dec6;
  --dark-brown: #f5d1ab;
  --error: #ff9696;
  --success: #a2c480;
  --info: #a1dfe6;
  --warning: #fffd99;
  --orange: #ff7445;
  --green: #c8cf8c;
  --black: #514342;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--light-brown);
  font-weight: 400;
  line-height: 1.75;
  color: #000000;
}

p {
  margin-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 3rem 0 1.38rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 1.3;
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

small,
.text_small {
  font-size: 0.8rem;
}

textarea:focus,
input:focus {
  outline: none;
}

textarea {
  width: 100%;
  min-height: 100px;
  border: 1px solid var(--light-grey);
  border-radius: 5px;
}

input[type='text'],
input[type='date'],
input[type='time'],
input[type='password'],
input[type='number'],
input[type='datetime-local'] {
  width: 100%;
  padding: 12px 20px;
  margin-top: 8px;
  display: inline-block;
  border: 1px solid var(--light-grey);
  box-sizing: border-box;
  border-radius: 5px;
}

input[type='number'] {
  appearance: textfield;
}

/* remove arrow fron number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select {
  width: 100%;
  padding: 12px 20px;
  margin-top: 8px;
  display: inline-block;
  border: 1px solid var(--light-grey);
  box-sizing: border-box;
  border-radius: 5px;
}

select:before {
  display: none;
}

.html-textarea-wrapper {
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  padding: 2px;
}

.form-field {
  margin-top: 12px;
  margin-bottom: 20px;
}

.field-error {
  color: var(--error);
  font-size: 12px;
}

.box {
  width: 360px;
  height: 410px;
  box-shadow: 4px 10px 18px -8px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  background-color: var(--white);
}

.button {
  background-color: var(--dark-brown);
  color: var(--black);
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 25px;
  font-size: 16px;
}

.button-success {
  background-color: var(--success);
  color: var(--white);
  padding: 10px;
  margin: 0;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 25px;
  font-size: 16px;
  min-width: 80px;
  text-decoration: none;
}

.button-error {
  background-color: var(--error);
  color: var(--white);
  padding: 10px;
  margin: 0;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 25px;
  font-size: 16px;
  min-width: 80px;
  text-decoration: none;
}

.circle-button {
  background-color: var(--dark-brown);
  color: var(--white);
  padding: 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  font-size: 20px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  display: flex;
  text-align: center;
  -moz-box-pack: center;
  justify-content: center;
  touch-action: none;
  -moz-box-align: center;
  align-items: center;
}

.button-fixed {
  width: 250px;
}

.circle-button:hover,
.button:hover {
  background-color: var(--light-brown);
}

.button:disabled {
  background-color: var(--light-grey);
  cursor: not-allowed;
}

.notificacion-is-error {
  background-color: #feecf0;
  color: #cc0f35;
  padding: 10px;
  border-radius: 5px;
}

sup {
  font-size: 11px;
  color: var(--grey);
}

.emoji {
  pointer-events: none;
}

.paginator-container {
  display: flex;
  width: 100px;
}

.message-container {
  padding: 10px;
}

.message-container div:nth-child(odd) {
  background-color: var(--light-brown);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 50px;
}

.message-container div:nth-child(even) {
  border: 1px solid var(--light-brown);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left: 50px;
}

.message-user {
  background-color: var(--light-brown) !important;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 50px;
  margin-left: 0 !important;
}

.message-admin {
  background-color: var(--white) !important;
  border: 1px solid var(--light-brown) !important;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left: 50px;
  margin-right: 0 !important;
}

.audio-message {
  display: grid;
  justify-content: end;
}

.textarea-container {
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid var(--light-grey);
}

.message-container-form {
  padding: 10px;
}

.recordButton {
  width: 50px;
  height: 50px;
  border: 5px solid var(--orange);
  background: var(--green);
  border-radius: 25px;
  color: var(--white);
  font-size: 24px;
}

.recordRecordButton {
  width: 50px;
  height: 50px;
  border: 5px solid var(--orange);
  background: var(--warning);
  border-radius: 25px;
  color: var(--black);
  font-size: 24px;
}
