.question-title {
  font-size: 14px;
}

.delete-question {
  float: right;
  background-color: var(--error);
  width: 10px;
  height: 10px;
  font-size: 10px;
  padding: 10px;
}
